<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-75"
    menu-class="dropdown-menu-media dropdown-menu-right"
    left
    v-show="notifications.length > 0"
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="DownloadCloudIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">{{ $t("downloads") }}</h4>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="notification in notifications" :key="notification.id">
        <b-media>
          <template #aside>
            <b-avatar size="32">
              <span class="d-flex align-items-center">
                <feather-icon
                  :icon="notification.error ? 'AlertTriangleIcon' : 'FileIcon'"
                  size="16"
                />
              </span>
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ `${notification.title}` }}
              <small class="notification-text float-right">{{
                notification.timestamp
              }}</small>
            </span>
          </p>
          <small class="notification-text">{{ notification.body }}</small>
          <b-progress
            key="progress"
            animated
            :value="notification.progress"
            variant="secondary"
            class="mt-1"
            v-if="!notification.completed && !notification.error"
          />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mt-1 d-block w-100"
            v-if="notification.completed && !notification.error"
            @click="downloadFile(notification.url, notification.download)"
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle">{{ $t("download") }}</span>
          </b-button>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        class="text-capitalize"
        @click="removeAllNotification()"
        >{{ $t("remove_all") }}</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BProgress,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { computed } from "@vue/composition-api";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BProgress,
  },
  directives: {
    Ripple,
  },
  setup() {
    const notifications = computed(() => store.getters["grid/getDownloadNotifications"]);

    const removeAllNotification = () => {
      store.dispatch("grid/removeAllDownloadNotification");
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const downloadFile = (url, download) => {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);

      if (download) link.setAttribute("download", download);

      link.click();
      link.remove();
    };

    return {
      notifications,
      perfectScrollbarSettings,
      removeAllNotification,
      downloadFile,
    };
  },
};
</script>

<style></style>
