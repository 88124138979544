class DOMEventEmitter {
  constructor(selector) {
    this._events = {};
    this._target = document.querySelector(selector) || window.document;
  }

  on(name, listener) {
    if (!this._events[name]) {
      this._events[name] = [];
    }

    this._events[name].push({ name, listener });

    return this._target.addEventListener(name, listener);
  }

  once(name, listener) {
    return this._target.addEventListener(name, listener, { once: true });
  }

  off(name, listenerToRemove) {
    if (!this._events[name]) {
      throw new Error(`Can't remove a listener. Event "${name}" doesn't exits.`);
    }

    this._events[name].forEach(event => {
      if (event.listener === listenerToRemove) {
        this._target.removeEventListener(event.name, event.listener);
      }
    });

    const filterListeners = (listener) => listener !== listenerToRemove;
    this._events[name] = this._events[name].filter(filterListeners);
  }

  offAll() {
    Object.keys(this._events).forEach(eventName => {
      this._events[eventName].forEach(event => {
        this._target.removeEventListener(event.name, event.listener);
      });
    });
  }

  emit(name, detail) {
    return this._target.dispatchEvent(
      new CustomEvent(name, { detail })
    );
  }
}

export default DOMEventEmitter;
