<template>
  <div class="navbar-container main-menu-content d-flex p-0">
    <!-- Nav Menu Toggler -->
    <!--    <ul class="nav navbar-nav d-xl-none">-->
    <!--      <li class="nav-item">-->
    <!--        <b-link class="nav-link" @click="toggleVerticalMenuActive">-->
    <!--          <feather-icon icon="MenuIcon" size="21" />-->
    <!--        </b-link>-->
    <!--      </li>-->
    <!--    </ul>-->

    <ul class="nav align-items-center mr-2">
      <b-link class="nav-link text-white" to="/">
        <img class="psim-icon" :src="appLogoImage" alt="SBO icon" />
        <span class="ml-1">SBO</span>
      </b-link>
    </ul>

    <div class="horizontal-menu-wrapper" v-if="userData && userData.video_wall !== 1">
      <a @click="clearBreadcrumbPath()">
        <horizontal-nav-menu-items
          :items="navMenuItems"
          ref="navmenu"
          :userData="userData"
        />
      </a>
    </div>

    <ul class="nav align-items-center ml-1" v-if="userData && userData.video_wall !== 1">
      <!-- <notification-dropdown class="mr-3" /> -->
      <!-- <chat-dropdown class="mr-3" /> -->
      <!-- <search-bar /> -->
      <b-link
        v-if="$route.name == 'home'"
        :class="[
          'nav-link btn-toggle-config-mode text-white',
          { 'config-mode-on': isConfigModeOn },
        ]"
        @click="toggleConfigMode"
      >
        <feather-icon icon="SettingsIcon" size="21" />
      </b-link>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-md-flex">
      <bookmarks v-if="userData && userData.video_wall !== 1" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto" v-if="userData && userData.video_wall === 0">
      <download-notification-dropdown />
      <sip-call-dropdown
        v-if="userDataForInterphone.device_interphone"
        :UserData="userDataForInterphone"
      />
      <notification-dropdown />
      <dark-toggler />
      <locale />
      <user-dropdown />
    </b-navbar-nav>

    <b-navbar-nav class="nav align-items-center ml-auto" v-if="userData && userData.video_wall === 1">
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import navMenuItems from "@/navigation/horizontal";
import store from "@/store";
import { BLink, BNavbarNav, BImg } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import DownloadNotificationDropdown from "./components/DownloadNotificactionDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import HorizontalNavMenuItems from "../../layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";
import ChatDropdown from "@/@core/layouts/components/app-navbar/components/ChatDropdown.vue";
import SipCallDropdown from "./components/SipCallDropdown.vue";
import { EventBus } from "@/libs/event-bus";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    BImg,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
    HorizontalNavMenuItems,
    ChatDropdown,
    DownloadNotificationDropdown,
    SipCallDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      navMenuItems,
      appLogoImage: require("@/assets/images/logo/icon-psim.png"),
      // appLogoImage: require("@/assets/images/konica-minolta-logo.png"),
      userData: this.$store.state.user.user,
      userDataForInterphone: this.$store.state.user.user,
    };
  },
  methods: {
    clearBreadcrumbPath() {
      store.dispatch("breadcrumbNav/clearBreadcrumbs");
    },
    toggleConfigMode() {
      store.commit(
        "psimConfig/TOGGLE_CONFIG_MODE",
        !store.state.psimConfig.configModeStatus
      );
    },
  },
  computed: {
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    // userData() {
    //   return this.$store.state.user.user;
    // },
  },
  created() {
    EventBus.on(`refresh_navbar_elements_for_user_update`, (params) => {
      console.log("on:refresh_navbar_elements_for_user_update");
      this.$ability.update(params.ability);//update casl abilities for nav menus we doit before the update for the userData param update
      this.userData = params;
      if (this.userDataForInterphone.device_interphone) {
        if (
          this.userDataForInterphone.device_interphone !=
          params.device_interphone
        ) {
          this.userDataForInterphone = params;
        }
      }
      // console.log({ params });
      // this.$ability.update(params.ability);
      //this.$refs.navmenu.updateKey += 1;
      //this.$refs.navmenu.$forceUpdate();
      //this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss">
@import "../../../scss/base/bootstrap-extended/include";
// Bootstrap includes
@import "../../../scss/base/components/include";
// Components includes
@import "../../../../assets/scss/variables/variables";

.psim-icon {
  height: 2rem;
  // height: 3.5rem;
}

.btn-toggle-config-mode {
  transition: transform, 0.2s;

  &.config-mode-on {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.horizontal-menu-wrapper {
  .nav-link {
    &.dropdown-toggle {
      &::after {
        left: 0.4rem;
        background-image: url(str-replace(
          str-replace($chevron-down, "currentColor", $body-color),
          "#",
          "%23"
        ));
      }
    }
  }

  .sidebar-group-active {
    .dropdown-toggle {
      &::after {
        background-image: url(str-replace(
          str-replace($chevron-down, "currentColor", $white),
          "#",
          "%23"
        ));
      }
    }
  }

  .dropdown-menu::before {
    display: none;
  }

  .dropdown-menu {
    min-width: 215px;
    border: none;
    margin-top: 0;
    min-height: 52px;

    .disabled {
      pointer-events: none !important;

      a {
        color: $gray-600;
      }
    }

    .dropdown-toggle::after {
      left: auto;
      position: absolute;
      top: 50%;
      margin-top: -7px;
      right: 1rem;
      // content: '\e844' !important;
      background-image: url(str-replace(
        str-replace($chevron-right, "currentColor", $body-color),
        "#",
        "%23"
      ));
    }

    .dropdown-item {
      font-size: 1rem;
      padding-top: 0.68rem;
      padding-bottom: 0.68rem;
      display: flex;
      align-items: center;
    }

    .dropdown-submenu {
      position: relative;

      &.openLeft {
        .dropdown-menu {
          left: auto !important;
          right: 100% !important;
        }
      }

      &.sidebar-group-active {
        background: $body-bg;
      }

      & > .dropdown-menu {
        position: absolute;
        top: 0 !important;
        left: 100% !important;

        i,
        svg {
          height: 11px !important;
          width: 11px !important;
          font-size: 11px !important;
        }
      }
    }
  }
}
</style>
